import React from 'react'
import Layout from '../components/Layout'
import { Container } from "react-bootstrap"
import '../styles/admissionDetails.css'
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../components/Head';


function AdmisssionDetails() {

    useEffect(() => {
        AOS.init({
          duration : 1000
        });
        AOS.refresh();
    
      }, []);

    return (
        <div className='overflow-hidden'>
            <Head title="Admission"/>
            <Layout>
                <Container className="object px-5">

                    
                    <section className='admission' >
                        <header className="sectionHeader halfBorder my-5" as="h1">Admisssion Process</header>

                        <div className="admissionRules"  >
                            <h3 style={{color:'#4BB266',textDecoration:'underline'}}><b>RULES REGARDING ADMISSION:</b></h3>
                            
                            <ul>
                                <li data-aos="flip-up" data-aos-duration='1000'>Application for admission duly filled should reach the Principal on or before the specified date.</li>
                                <li data-aos="flip-up" data-aos-duration='1200'>No application will be considered unless accompanied by copies of:
                                    <ul style={{listStyleType:"square"}}>
                                        <li style={{listStyleType:'disc'}}>The Transfer Certificate from the Institution leist attended.</li>
                                        <li style={{listStyleType:'disc'}}>The statement of marks obtained at the last examination.</li>
                                        <li style={{listStyleType:'disc'}}>The Medical Examination Certificate from the previous institution.</li>
                                    </ul>
                                </li>
                                <li data-aos="flip-up" data-aos-duration='1300'>Only Copies of the certificates should be attached and the Originals should be produced at the time of admission.</li>
                                <li data-aos="flip-up" data-aos-duration='1400'>Migration Certificate should be submitted in the case of students coming from other Universities.</li>
                                <li data-aos="flip-up" data-aos-duration='1500'>The languages opted must be the same as those languages opted at the previous qualifying examination passed by the candidate. The College offers Kannada, Sanskrit and Hindi languages.</li>
                                <li data-aos="flip-up" data-aos-duration='1600'>Candidates selected for admission should pay the prescribed fees and produce the originals of the Transfer Certificate, the statement of marks on or before the dates notified and should meet tile Principal to get themselves admitted.</li>
                                <li data-aos="flip-up" data-aos-duration='1700'>Eight(8) passport size photographs and Three(3) B2 size family photographs(for hostel students).</li>
                                <li data-aos="flip-up" data-aos-duration='1800'> All admissions are provisional and are subject to the approval of the Pre-University Education Board Rules of discipline.</li>
                            </ul>
                        </div>

                        {/* <div className="admissionBg" data-aos="zoom-in" data-aos-duration='1500'></div> */}
                        
                    </section>
        
                </Container>


            </Layout>
            
        </div>
    )
}

export default AdmisssionDetails

